<template>
  <div>
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ $t(item.text) }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row>
      <v-col cols="12">
        <h2>{{ pageName }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="no-padding">
        <v-tabs v-model="tab" class="tabs" background-color="#ffffff" dark>
          <v-tab @click="routeTab(0)">{{ $t("Nidaa") }}</v-tab>
          <v-tab @click="routeTab(1)">{{ $t("History") }}</v-tab>
          <v-tab @click="routeTab(2)" v-if="isNotDriver">{{
            $t("Driver")
          }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-card>
      <v-tabs-items v-model="tab">
        <!-- Nidaa -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div class="my-8">
                <!-- :options.sync="options" -->
                <v-data-table
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  :headers="nidaaHeaders"
                  :items="nidaaItems"
                  item-key="id"
                  :show-select="false"
                  class="elevation-1 level1 templates text-center"
                  text="left"
                  hide-default-footer
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      @click="getCurrentPosition('call', item.id)"
                      icon
                      :disabled="!item.can_call"
                      v-if="!item.show_retry"
                      color="#7297ff"
                    >
                      <v-icon :title="$t('Call')"
                        >record_voice_over</v-icon
                      ></v-btn
                    >
                    <v-btn
                      @click="
                        getCurrentPosition('retry', item.id, item.call_id)
                      "
                      icon
                      v-else
                      color="#7297ff"
                    >
                      <v-icon :title="$t('Retry')">autorenew</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- History -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div class="my-8">
                <v-row class="kids mt-5">
                  <v-col
                    cols="6"
                    sm="3"
                    class="py-0 mb-4"
                    v-for="(student, index) in nidaaItems"
                    :key="index"
                  >
                    <v-row
                      class="kid"
                      :class="student.id == historyStudentId ? 'active' : ''"
                      @click="MakeHimActive(index)"
                    >
                      <v-col cols="4">
                        <img
                          :src="student.logo"
                          v-if="student.logo"
                          alt="student image"
                          class="student-image"
                        />
                        <img
                          v-else
                          src="../../../assets/avatar.png"
                          class="student-image"
                          alt=""
                        />
                      </v-col>
                      <v-col cols="8">
                        <p class="student-name">{{ student.name }}</p>
                      </v-col>
                      <v-col cols="6" class="py-0 px-0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              class="student-grade mb-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ student.grade }}
                            </p>
                          </template>
                          <span>{{ student.grade }}</span>
                        </v-tooltip>
                      </v-col>

                      <v-col cols="6" class="py-0" v-if="student.class">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              class="text-right student-class mb-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ student.class }}
                            </p>
                          </template>
                          <span>{{ student.class }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- :options.sync="options" -->
                <v-data-table
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  :headers="historyHeaders"
                  :items="historyItems"
                  item-key="id"
                  :show-select="false"
                  class="elevation-1 level1 templates text-center"
                  text="left"
                  hide-default-footer
                  :disable-pagination="true"
                >
                </v-data-table>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Driver -->
        <v-tab-item v-if="isNotDriver">
          <v-card flat>
            <v-card-text>
              <v-row class="headerTable-div mt-5">
                <v-col md="8" sm="12">
                  <h3 class="custom-header main-color">{{ $t("Driver") }}</h3>
                </v-col>
                <v-col md="4" sm="12">
                  <v-icon
                    :title="$t('Add Driver')"
                    v-if="canAddDriver"
                    class="main-color lg"
                    @click="AddEditdialog = true"
                    large
                    >add_circle</v-icon
                  >
                </v-col>
              </v-row>
              <div class="my-8">
                <!-- :options.sync="options" -->
                <v-data-table
                  ref="driverTable"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  :headers="driverHeaders"
                  :items="driverItems"
                  item-key="id"
                  :show-select="false"
                  class="elevation-1 level1 templates text-center"
                  text="left"
                  hide-default-footer
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      :title="$t('Edit Driver')"
                      v-if="item.can_access"
                      @click="editDriver(item)"
                      >edit</v-icon
                    >
                    <v-icon
                      :title="$t('Delete Driver')"
                      v-if="item.can_access"
                      @click="showDeleteDriverDial(item.id)"
                      >delete</v-icon
                    >
                  </template>
                </v-data-table>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- ADD EDIT DRIVER -->
    <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Add Driver") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="form" class="py-4">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("First Name") }} </label>
                  <v-text-field
                    v-model="editedItem.first_name"
                    :rules="[
                      validationRules.required,
                      validationRules.maxLength50,
                    ]"
                    @focus="onFocus()"
                    solo
                  ></v-text-field>
                  <div v-if="validation_errors.first_name">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.first_name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Last Name") }} </label>
                  <v-text-field
                    v-model="editedItem.last_name"
                    :rules="[
                      validationRules.required,
                      validationRules.maxLength100,
                    ]"
                    @focus="onFocus()"
                    solo
                  ></v-text-field>
                  <div v-if="validation_errors.last_name">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.last_name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Mobile") }}</label>
                  <v-text-field
                    v-model="editedItem.mobile"
                    solo
                    @focus="onFocus()"
                    :rules="[
                      validationRules.required,
                      mobileRule(editedItem.country_code_id, editedItem.mobile),
                    ]"
                  ></v-text-field>
                  <div v-if="validation_errors.mobile">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.mobile"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("National ID") }} </label>

                  <v-text-field
                    v-model="editedItem.national_id"
                    solo
                    @focus="onFocus()"
                    :rules="[
                      validationRules.required,
                      validationRules.maxLength100,
                    ]"
                    type="number"
                  ></v-text-field>
                  <div v-if="validation_errors.national_id">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.national_id"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>

                <v-col v-if="editMode" cols="12" sm="12" md="12">
                  <label>{{ $t("Email") }} </label>

                  <v-text-field
                    v-model="editedItem.email"
                    solo
                    @focus="onFocus()"
                    :rules="[
                      validationRules.required,
                      validationRules.required,
                      validationRules.email,
                    ]"
                  ></v-text-field>
                </v-col>

                <v-col v-if="editMode" cols="12" sm="12" md="12">
                  <label>{{ $t("Pssword") }} </label>

                  <v-text-field
                    v-model="editedItem.password"
                    solo
                    @focus="onFocus()"
                    type="password"
                    :rules="[
                      validationRules.required,
                      validationRules.maxLength100,
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="close">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save"
            @click="updateDriver"
            v-if="editMode"
            :loading="loading"
            :disabled="loading"
            >{{ $t("Edit") }}</v-btn
          >
          <v-btn
            class="modal-btn-save"
            @click="addDriver"
            v-else
            :loading="loading"
            :disabled="loading"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /ADD EDIT DRIVER -->

    <!-- Delete driver confirmation message -->
    <v-dialog
      v-model="dialogReset"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to Delet Driver ?")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="dialogReset = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteDriver(editedItem.id)"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- snackBar -->
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snack"
        :color="color"
        :timeout="timeout"
        :top="true"
        :right="true"
      >
        {{ msg }}
        <v-btn color="pink" text @click="snack = false">{{
          $t("Close")
        }}</v-btn>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import ACL from "../../../acl";
import { validationMixin } from "../../../mixins/validationMixin";

export default {
  name: "Nidaa",
  components: {
    // DataTableComponent,
  },
  mixins: [validationMixin],
  data() {
    return {
      isNotDriver: !ACL.Role("Driver"),
      tab: null,
      options: {
        itemsPerPage: 15,
      },
      valid: false,
      pageName: this.$i18n.t("Nidaa"),
      AddEditdialog: false,
      historyStudentId: null,
      childArr: [],
      nidaaHeaders: [
        { text: this.$i18n.t("Child Name"), value: "name", sortable: false },
        { text: this.$i18n.t("Grade"), value: "grade", sortable: false },
        { text: this.$i18n.t("Class"), value: "class", sortable: false },
        {
          text: this.$i18n.t("Leave Time"),
          value: "leave_time",
          sortable: false,
        },
        {
          text: this.$i18n.t("Call Time"),
          value: "call_time",
          sortable: false,
        },
        {
          text: this.$i18n.t("Checkout Time"),
          value: "checkout_time",
          sortable: false,
        },
        {
          text: this.$i18n.t("Call/Retry"),
          value: "actions",
          sortable: false,
          width: "12%",
        },
      ],
      editedItem: {
        id: "",
        first_name: "",
        last_name: "",
        mobile: "",
        national_id: "",
        email: "",
        password: "",
        country_code_id: 187,
      },
      nidaaItems: [],
      driverHeaders: [
        {
          text: this.$i18n.t("First Name"),
          value: "first_name",
          sortable: false,
        },
        {
          text: this.$i18n.t("Last Name"),
          value: "last_name",
          sortable: false,
        },
        {
          text: this.$i18n.t("Phone Number"),
          value: "mobile",
          sortable: false,
        },
        { text: this.$i18n.t("Email"), value: "email", sortable: false },
        { text: this.$i18n.t("Password"), value: "password", sortable: false },
        {
          text: this.$i18n.t("National ID"),
          value: "national_id",
          sortable: false,
        },
        {
          text: this.$i18n.t("Actions"),
          value: "actions",
          sortable: false,
          width: "12%",
        },
      ],
      driverItems: [],
      historyHeaders: [
        { text: this.$i18n.t("Date"), value: "date", sortable: false },
        {
          text: this.$i18n.t("Call Time"),
          value: "call_time",
          sortable: false,
        },
        {
          text: this.$i18n.t("Called By"),
          value: "called_by",
          sortable: false,
        },
        {
          text: this.$i18n.t("Checkout Time"),
          value: "checkout_time",
          sortable: false,
        },
      ],
      validation_errors: {
        first_name: "",
        last_name: "",
        mobile: [],
        national_id: [],
      },
      editMode: false,
      canAddDriver: false,
      loading: false,
      historyItems: [],
      currentLat: null,
      currentLng: null,
      /* snackBar */
      snack: false,
      dialogReset: false,
      msg: "",
      color: "success",
      timeout: 2000,

      /*
       *
       *
       *
       *
       *
       *
       *
       *
       */
    };
  },

  methods: {
    onFocus() {
      this.validation_errors = [];
    },
    getMyChildren() {
      this.$http
        .get(this.getApiUrl + "/nedaa/getChilders", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          var _this = this;
          this.nidaaItems = response.data.data;
          if (this.nidaaItems.length > 0) {
            this.historyStudentId = this.nidaaItems[0].id;
            this.getCallHistory();

            // eslint-disable-next-line no-unused-vars
            this.nidaaItems.forEach((child, index) => {
              _this.childArr.push(child.id);
            });
          }
          this.loading = false;
        });
    },
    addDriver() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.$http
          .post(
            this.getApiUrl + "/nedaa/addDriver",
            {
              first_name: this.editedItem.first_name,
              last_name: this.editedItem.last_name,
              mobile: this.editedItem.mobile,
              national_id: this.editedItem.national_id,
              country_code_id: this.editedItem.country_code_id,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.AddEditdialog = false;
              this.snack = true;
              this.color = "green";
              this.msg = response.data.status.message;
              // this.close();

              // this.getDrivers();

              window.location.reload();
              //this.routeTab(2)
            } else {
              // show error
              this.snack = true;
              this.color = "red";
              this.validation_errors = response.data.status.validation_errors;
              this.msg = response.data.status.message;
            }
            this.loading = false;
          });
      }
    },
    close() {
      this.AddEditdialog = false;
      this.editedItem = {};
      this.editMode = false;
      this.validation_errors = {
        first_name: "",
        last_name: "",
        mobile: [],
        national_id: [],
      };
    },
    showDeleteDriverDial(id) {
      this.editedItem.id = id;
      this.dialogReset = true;
    },
    deleteDriver() {
      this.$http
        .get(this.getApiUrl + "/nedaa/deleteDriver/" + this.editedItem.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.snack = true;
          this.color = "green";
          this.msg = response.data.status.message;
          this.loading = false;
          this.dialogReset = false;
          // this.getDrivers();
          window.location.reload();
        });
    },
    editDriver(item) {
      this.editedItem = {
        id: item.id,
        first_name: item.first_name,
        last_name: item.last_name,
        mobile: item.mobile,
        national_id: item.national_id,
        country_code_id: 187,
        email: item.email,
        password: item.password,
      };
      this.editMode = true;
      this.AddEditdialog = true;
    },
    updateDriver() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.$http
          .post(
            this.getApiUrl + "/nedaa/updateDriver/" + this.editedItem.id,
            {
              first_name: this.editedItem.first_name,
              last_name: this.editedItem.last_name,
              mobile: this.editedItem.mobile,
              national_id: this.editedItem.national_id,
              country_code_id: this.editedItem.country_code_id,
              email: this.editedItem.email,
              password: this.editedItem.password,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.AddEditdialog = false;
              this.snack = true;
              this.color = "green";
              this.msg = response.data.status.message;
              // this.close();
              // this.getDrivers();
              window.location.reload();
              //this.routeTab(2)
            } else {
              // show error
              this.snack = true;
              this.color = "red";
              this.validation_errors = response.data.status.validation_errors;
              this.msg = response.data.status.message;
            }
            this.loading = false;
          });
      }
    },
    callStudent(studentId) {
      if (this.currentLat) {
        this.$http
          .post(
            this.getApiUrl + "/nedaa/callStudent",
            {
              student_id: studentId,
              lat: this.currentLat,
              long: this.currentLng,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.snack = true;
              this.color = "green";
              this.msg = response.data.status.message;
              this.getMyChildren();
            } else {
              // show error
              this.snack = true;
              this.color = "red";
              this.msg = response.data.status.message;
            }
            this.loading = false;
          });
      }
    },
    retryCall(studentId, callId) {
      this.$http
        .post(
          this.getApiUrl + "/nedaa/retry",
          {
            student_id: studentId,
            cal_id: callId,
            lat: this.currentLat,
            long: this.currentLng,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.snack = true;
            this.color = "green";
            this.msg = response.data.status.message;
            this.getMyChildren();
          } else {
            // show error
            this.snack = true;
            this.color = "red";
            this.msg = response.data.status.message;
          }
          this.loading = false;
        });
    },
    getDrivers() {
      this.$http
        .get(this.getApiUrl + "/nedaa/showDriver", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.data == null || response.data.data == "undefind") {
            this.canAddDriver = true;
            // this.driverItems = [];
          } else {
            this.driverItems[0] = response.data.data;
          }

          this.loading = false;
        });
    },

    getCallHistory() {
      if (this.historyStudentId) {
        this.$http
          .get(this.getApiUrl + "/nedaa/callHisory/" + this.historyStudentId, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            if (response.data.data == null) {
              this.historyItems = [];
            } else {
              this.historyItems = response.data.data;
            }
            this.loading = false;
          });
      }
    },

    MakeHimActive(index) {
      this.historyStudentId = this.nidaaItems[index].id;
      this.getCallHistory();
    },
    getCurrentPosition(calltype, studentId, callId = null) {
      var _this = this;
      navigator.geolocation.getCurrentPosition((position) => {
        _this.currentLat = position.coords.latitude;
        _this.currentLng = position.coords.longitude;
        if (calltype == "call") {
          _this.callStudent(studentId);
        } else if (calltype == "retry") {
          _this.retryCall(studentId, callId);
        }
      });
    },
    routeTab(tab) {
      if (tab == 0) {
        this.getMyChildren();
      } else if (tab == 1) {
        this.getCallHistory();
      } else if (tab == 2) {
        this.getDrivers();
        // console.log("ffffff");
      }
    },
  },
  created() {
    this.getMyChildren();
    this.getCurrentPosition("", 1);
  },
  mounted() {
    var _this = this;
    // this.$socket.channel("StudentCallList").listen("StudentCallList", (e) => {
    //   _this.getMyChildren();
    //   console.log(e);
    // });
    if (process.env.VUE_APP_SOCKET_URL != undefined) {
      this.$socket
        .channel("StudentCallStatusChanged")
        .listen("StudentCallStatusChanged", (e) => {
          console.log("StudentCallStatusChanged event...");
          console.log(e);
          if (_this.childArr.includes(parseInt(e.student_id))) {
            _this.getMyChildren();
            console.log("StudentCallStatusChanged called");
          }
        });
    }
    this.getDrivers();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/_modals.scss";
.kids {
  margin-bottom: 20px;
  .kid {
    border: 1px solid #dddddd;
    background-color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    height: 140px;
    overflow: hidden;

    .student-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .student-name {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      color: #000000;
    }
    .student-grade,
    .student-class {
      color: #000000;
      margin-left: 10px;
    }
  }
  .kid.active {
    background-color: #d8e2ff;
    cursor: default;
  }
}
.search-icon {
  margin-right: 30px;
}
.checkbox {
  padding: 0 !important;
  margin: 0 !important;
}

.table td {
  padding: 10px 10px 0;
}

.add-icon {
  margin-right: 20px;
}

// start search input
.search {
  display: inline-block;
  position: relative;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  margin: 0px 8px 7px 0px;
  padding: 7px 9px 0px 9px;
  border: 2px solid #bbbbbb;
  border-radius: 25px;
  transition: all 200ms ease;
  cursor: text;
  padding: 3px 5px;

  &:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 15px;
    right: -5px;
    top: 21px;
    background: #bbbbbb;
    border-radius: 3px;
    transform: rotate(-45deg);
    transition: all 200ms ease;
  }

  &.active,
  &:hover {
    width: 200px;
    margin-right: 0px;

    &:after {
      height: 0px;
    }
  }

  input {
    width: 100%;
    border: none;
    box-sizing: border-box;
    font-family: Helvetica;
    font-size: 15px;
    color: inherit;
    background: transparent;
    outline-width: 0px;
  }
}

.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}
.chart_icon {
  color: rgba(0, 0, 0, 0.54);
  font-size: 18px;
  padding-right: 2px !important;
  margin-bottom: -2px !important;
  cursor: pointer;
}
.pull-right {
  float: right;
  padding: 15px;
  margin: 20px 0;
}
// end search input
</style>
